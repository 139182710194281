import Vue from 'vue';
import VueRouter from 'vue-router';

import Auth from '@/auth';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      redirect: '/general-information',
    },

    // Configuration
    {
      path: '/general-information',
      name: 'general-information',
      component: () => import('@/views/GeneralInformation.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Podstawowe dane',
        breadcrumb: [
          {
            text: 'Podstawowe dane',
            active: true,
          },
        ],
      },
    },
    {
      path: '/free-delivery',
      name: 'free-delivery',
      component: () => import('@/views/FreeDelivery.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Darmowa dostawa',
        breadcrumb: [
          {
            text: 'Darmowa dostawa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/Categories.vue'),
    },
    {
      path: '/categories-description',
      name: 'categories-description',
      component: () => import('@/views/CategoriesDescription.vue'),
    },

    // Navigation
    {
      path: '/navigation',
      name: 'navigation-index',
      component: () => import('@/views/navigation/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Nawigacja i kategorie',
        navActiveLink: 'navigation-index',
        breadcrumb: [
          {
            text: 'Nawigacja i kategorie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/navigation/create',
      name: 'navigation-create',
      component: () => import('@/views/navigation/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie pozycji nawigacji',
        navActiveLink: 'navigation-index',
        breadcrumb: [
          {
            text: 'Nawigacja i kategorie',
            to: '/navigation',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/navigation/edit/:id',
      name: 'navigation-edit',
      component: () => import('@/views/navigation/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja pozycji nawigacji',
        navActiveLink: 'navigation-index',
        breadcrumb: [
          {
            text: 'Nawigacja i kategorie',
            to: '/navigation',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Static pages
    {
      path: '/seo/pages',
      name: 'seo-index',
      component: () => import('@/views/pages/Seo.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'SEO',
        breadcrumb: [
          {
            text: 'SEO',
            active: true,
          },
        ],
      },
    },

    {
      path: '/pages/general-terms-of-sale',
      name: 'general-terms-of-sale',
      component: () => import('@/views/pages/GeneralTermsOfSale.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Ogólne Warunki Sprzedaży',
        breadcrumb: [
          {
            text: 'Strony statyczne - Ogólne Warunki Sprzedaży',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/pages/PrivacyPolicy.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Polityka prywatności',
        breadcrumb: [
          {
            text: 'Strony statyczne - Polityka prywatności',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/statute',
      name: 'statute',
      component: () => import('@/views/pages/Statute.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Regulamin',
        breadcrumb: [
          {
            text: 'Strony statyczne - Regulamin',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/shareholders-communication',
      name: 'shareholders-communication',
      component: () => import('@/views/pages/ShareholdersCommunication.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Komunikacja z akcjonariuszami',
        breadcrumb: [
          {
            text: 'Strony statyczne - Komunikacja z akcjonariuszami',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/about-us',
      name: 'about-us',
      component: () => import('@/views/pages/AboutUs.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'O nas',
        breadcrumb: [
          {
            text: 'Strony statyczne - O nas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/for-designers',
      name: 'for-designers-page',
      component: () => import('@/views/pages/ForDesigners.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dla designerów',
        breadcrumb: [
          {
            text: 'Strony statyczne - Dla designerów',
            active: true,
          },
        ],
      },
    },

    // Static elements
    {
      path: '/elements/carousel-items',
      name: 'carousel-items',
      component: () => import('@/views/elements/CarouselItems.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Karuzela',
        breadcrumb: [
          {
            text: 'Elementy statyczne - karuzela',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/hero-section-carousel',
      name: 'hero-section-carousel',
      component: () => import('@/views/elements/HeroSectionCarousel.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Karuzela na stronie głównej',
        breadcrumb: [
          {
            text: 'Elementy statyczne - karuzela',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/invena-in-numbers',
      name: 'invena-in-numbers',
      component: () => import('@/views/elements/InvenaInNumbers.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Invena w liczbach',
        breadcrumb: [
          {
            text: 'Invena w liczbach',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/guarantee-details',
      name: 'guarantee-details',
      component: () => import('@/views/elements/GuaranteeDetails.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Trwanie gwarancji',
        breadcrumb: [
          {
            text: 'Trwanie gwarancji',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/about-us-homepage',
      name: 'about-us-homepage',
      component: () => import('@/views/elements/AboutUsHomepage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'O nas - strona główna',
        breadcrumb: [
          {
            text: 'O nas - strona główna',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about-us-reviews',
      name: 'about-us-reviews-index',
      component: () => import('@/views/about-us-reviews/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'O nas - opinie',
        breadcrumb: [
          {
            text: 'O nas - opinie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about-us-reviews/create',
      name: 'about-us-reviews-create',
      component: () => import('@/views/about-us-reviews/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie opinii',
        navActiveLink: 'about-us-reviews-index',
        breadcrumb: [
          {
            text: 'O nas - opinie',
            to: '/about-us-reviews',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/about-us-reviews/:id/edit',
      name: 'about-us-reviews-edit',
      component: () => import('@/views/about-us-reviews/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja opinii',
        navActiveLink: 'about-us-reviews-index',
        breadcrumb: [
          {
            text: 'O nas - opinie',
            to: '/about-us-reviews',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/delivery-methods-additional',
      name: 'delivery-methods-additional-information',
      component: () => import('@/views/elements/DeliveryMethodsAdditionalInformation.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Metody dostawy - dodatkowe informacje',
        breadcrumb: [
          {
            text: 'Metody dostawy - dodatkowe informacje',
            active: true,
          },
        ],
      },
    },
    {
      path: '/elements/for-designers',
      name: 'for-designers',
      component: () => import('@/views/elements/ForDesignersHomepage.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dla designerów - strona główna',
        breadcrumb: [
          {
            text: 'Dla designerów - strona główna',
            active: true,
          },
        ],
      },
    },

    // BLOG
    {
      path: '/blog/posts',
      name: 'blog-posts-index',
      component: () => import('@/views/blog/posts/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Blog - Posty',
        breadcrumb: [
          {
            text: 'Blog - Posty',
            to: '/blog/posts',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog/posts/create',
      name: 'blog-posts-create',
      component: () => import('@/views/blog/posts/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie postu',
        navActiveLink: 'blog-posts-index',
        breadcrumb: [
          {
            text: 'Blog - Posty',
            to: '/blog/posts',
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog/posts/:slug',
      name: 'blog-posts-edit',
      component: () => import('@/views/blog/posts/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja Postu',
        navActiveLink: 'blog-posts-index',
        breadcrumb: [
          {
            text: 'Blog - Posty',
            to: '/blog/posts',
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog/categories',
      name: 'blog-categories-index',
      component: () => import('@/views/blog/categories/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Blog - Kategorie',
        breadcrumb: [
          {
            text: 'Blog - Kategorie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog/categories/create',
      name: 'blog-categories-create',
      component: () => import('@/views/blog/categories/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie Kategorii',
        navActiveLink: 'blog-categories-index',
        breadcrumb: [
          {
            text: 'Blog - Kategorie',
            to: '/blog/categories',
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/blog/categories/:id',
      name: 'blog-categories-edit',
      component: () => import('@/views/blog/categories/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja Kategorii',
        navActiveLink: 'blog-categories-index',
        breadcrumb: [
          {
            text: 'Blog - Kategorie',
            to: '/blog/categories',
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Inspirations
    {
      path: '/inspirations-page',
      name: 'inspirations-page-index',
      component: () => import('@/views/inspirations/pages/Inspirations.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Inspiracje - strona',
        breadcrumb: [
          {
            text: 'Inspiracje - strona',
            to: '/inspirations-page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inspirations',
      name: 'inspirations-index',
      component: () => import('@/views/inspirations/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Inspiracje',
        breadcrumb: [
          {
            text: 'Inspiracje',
            to: '/inspirations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inspirations/create',
      name: 'inspirations-create',
      component: () => import('@/views/inspirations/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie inspiracji',
        navActiveLink: 'inspirations-index',
        breadcrumb: [
          {
            text: 'Inspiracje',
            to: '/inspirations',
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inspirations/:slug',
      name: 'inspirations-edit',
      component: () => import('@/views/inspirations/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja inspiracji',
        navActiveLink: 'inspirations-index',
        breadcrumb: [
          {
            text: 'Inspiracje',
            to: '/inspirations',
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    // ------------------------------ SALES ------------------------------

    // Orders
    {
      path: '/orders',
      name: 'orders-index',
      component: () => import('@/views/orders/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Zamówienia',
        breadcrumb: [
          {
            text: 'Zamówienia',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders/:id',
      name: 'orders-show',
      component: () => import('@/views/orders/Show.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Szczegóły zamówienia',
        navActiveLink: 'orders-index',
        breadcrumb: [
          {
            text: 'Zamówienia',
            to: '/orders',
            active: false,
          },
          {
            text: 'Szczegóły zamówienia',
            active: true,
          },
        ],
      },
    },

    // Delivery methods
    {
      path: '/delivery-methods',
      name: 'delivery-methods-index',
      component: () => import('@/views/delivery-methods/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Metody dostawy',
        breadcrumb: [
          {
            text: 'Metody dostawy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/delivery-methods/create',
      name: 'delivery-methods-create',
      component: () => import('@/views/delivery-methods/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie metody dostawy',
        navActiveLink: 'delivery-methods-index',
        breadcrumb: [
          {
            text: 'Metody dostawy',
            to: '/delivery-methods',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/delivery-methods/:id',
      name: 'delivery-methods-edit',
      component: () => import('@/views/delivery-methods/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja metody dostawy',
        navActiveLink: 'delivery-methods-index',
        breadcrumb: [
          {
            text: 'Metody dostawy',
            to: '/delivery-methods',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Discount codes
    {
      path: '/discount-codes',
      name: 'discount-codes-index',
      component: () => import('@/views/discount-codes/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Kody rabatowe',
        breadcrumb: [
          {
            text: 'Kody rabatowe',
            active: true,
          },
        ],
      },
    },
    {
      path: '/discount-codes/create',
      name: 'discount-codes-create',
      component: () => import('@/views/discount-codes/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie kodu rabatowego',
        navActiveLink: 'discount-codes-index',
        breadcrumb: [
          {
            text: 'Kody rabatowe',
            to: '/discount-codes',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: 'discount-codes/:id',
      name: 'discount-codes-edit',
      component: () => import('@/views/discount-codes/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja kodu rabatowego',
        navActiveLink: 'discount-codes-index',
        breadcrumb: [
          {
            text: 'Metody dostawy',
            to: '/discount-codes',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Sale representatives
    {
      path: '/sale-representatives',
      name: 'sale-representatives-index',
      component: () => import('@/views/sale-representatives/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Przedstawiciele handlowi',
        navActiveLink: 'sale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele handlowi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sale-representatives/create',
      name: 'sale-representatives-create',
      component: () => import('@/views/sale-representatives/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie przedstawiciela handlowego',
        navActiveLink: 'sale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele handlowi',
            to: '/sale-representatives',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sale-representatives/:id',
      name: 'sale-representatives-edit',
      component: () => import('@/views/sale-representatives/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja przedstawiciela handlowego',
        navActiveLink: 'sale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele handlowi',
            to: '/sale-representatives',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Wholesale representatives
    {
      path: '/wholesale-representatives',
      name: 'wholesale-representatives-index',
      component: () => import('@/views/wholesale-representatives/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Przedstawiciele zagraniczni',
        navActiveLink: 'wholesale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele zagraniczni',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wholesale-representatives/create',
      name: 'wholesale-representatives-create',
      component: () => import('@/views/wholesale-representatives/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie przedstawiciela zagranicznego',
        navActiveLink: 'wholesale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele zagraniczni',
            to: '/wholesale-representatives',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wholesale-representatives/:id',
      name: 'wholesale-representatives-edit',
      component: () => import('@/views/wholesale-representatives/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja przedstawiciela zagraicznego',
        navActiveLink: 'wholesale-representatives-index',
        breadcrumb: [
          {
            text: 'Przedstawiciele zagraniczni',
            to: '/wholesale-representatives',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Technicians
    {
      path: '/technicians',
      name: 'technicians-index',
      component: () => import('@/views/technicians/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Serwisanci',
        navActiveLink: 'technicians-index',
        breadcrumb: [
          {
            text: 'Serwisanci',
            active: true,
          },
        ],
      },
    },
    {
      path: '/technicians/create',
      name: 'technicians-create',
      component: () => import('@/views/technicians/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie serwisanta',
        navActiveLink: 'technicians-index',
        breadcrumb: [
          {
            text: 'Serwisanci',
            to: '/technicians',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/technicians/:id',
      name: 'technicians-edit',
      component: () => import('@/views/technicians/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja serwisanta',
        navActiveLink: 'technicians-index',
        breadcrumb: [
          {
            text: 'Serwisanci',
            to: '/technicians',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // ------------------- OTHERS -------------------

    // Job offers
    {
      path: '/job-offers',
      name: 'job-offers-index',
      component: () => import('@/views/job-offers/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Oferty pracy',
        breadcrumb: [
          {
            text: 'Oferty pracy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/job-offers/create',
      name: 'job-offers-create',
      component: () => import('@/views/job-offers/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie oferty pracy',
        navActiveLink: 'job-offers-index',
        breadcrumb: [
          {
            text: 'Oferty pracy',
            to: '/job-offers',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/job-offers/:id',
      name: 'job-offers-edit',
      component: () => import('@/views/job-offers/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja oferty pracy',
        navActiveLink: 'job-offers-index',
        breadcrumb: [
          {
            text: 'Oferty pracy',
            to: '/job-offers',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Downloadable files
    {
      path: '/downloadable-files',
      name: 'downloadable-files-index',
      component: () => import('@/views/downloadable-files/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Pliki do pobrania',
        breadcrumb: [
          {
            text: 'Pliki do pobrania',
            active: true,
          },
        ],
      },
    },
    {
      path: '/downloadable-files/create',
      name: 'downloadable-files-create',
      component: () => import('@/views/downloadable-files/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie pliku do pobrania',
        navActiveLink: 'downloadable-files-index',
        breadcrumb: [
          {
            text: 'Pliki do pobrania',
            to: '/downloadable-files',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/downloadable-files/:id',
      name: 'downloadable-files-edit',
      component: () => import('@/views/downloadable-files/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja pliku do pobrania',
        navActiveLink: 'downloadable-files-index',
        breadcrumb: [
          {
            text: 'Pliki do pobrania',
            to: '/downloadable-files',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Downloadable file tags
    {
      path: '/downloadable-files-tags',
      name: 'downloadable-files-tags-index',
      component: () => import('@/views/downloadable-files-tags/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Tagi plików do pobrania',
        breadcrumb: [
          {
            text: 'Tagi plików do pobrania',
            active: true,
          },
        ],
      },
    },
    {
      path: '/downloadable-files-tags/create',
      name: 'downloadable-files-tags-create',
      component: () => import('@/views/downloadable-files-tags/Create.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Dodawanie tagu pliku do pobrania',
        navActiveLink: 'downloadable-files-tags-index',
        breadcrumb: [
          {
            text: 'Tagi plików do pobrania',
            to: '/downloadable-files-tags',
            active: false,
          },
          {
            text: 'Dodawanie',
            active: true,
          },
        ],
      },
    },
    {
      path: '/downloadable-files-tags/:id',
      name: 'downloadable-files-tags-edit',
      component: () => import('@/views/downloadable-files-tags/Edit.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Edycja tagu pliku do pobrania',
        navActiveLink: 'downloadable-files-tags-index',
        breadcrumb: [
          {
            text: 'Pliki do pobrania',
            to: '/downloadable-files-tags',
            active: false,
          },
          {
            text: 'Edycja',
            active: true,
          },
        ],
      },
    },

    // Cooperation
    {
      path: '/cooperation',
      name: 'cooperation-index',
      component: () => import('@/views/cooperation/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Współpraca',
        breadcrumb: [
          {
            text: 'Współpraca',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cooperation/:id',
      name: 'cooperation-show',
      component: () => import('@/views/cooperation/Show.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Szczegóły zgłoszenia',
        navActiveLink: 'cooperation-index',
        breadcrumb: [
          {
            text: 'Współpraca',
            to: '/cooperation',
            active: false,
          },
          {
            text: 'Szczegóły zgłoszenia',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat',
      name: 'chat-index',
      component: () => import('@/views/chat/Index.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Chat',
        breadcrumb: [
          {
            text: 'Chat',
            active: true,
          },
        ],
      },
    },
    {
      path: '/chat/:id',
      name: 'chat-show',
      component: () => import('@/views/chat/Show.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Szczegóły zgłoszenia',
        navActiveLink: 'chat-index',
        breadcrumb: [
          {
            text: 'Chat',
            to: '/chat',
            active: false,
          },
          {
            text: 'Szczegóły zgłoszenia',
            active: true,
          },
        ],
      },
    },

    // System
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password/reset',
      name: 'password-reset',
      component: () => import('@/views/PasswordReset.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/password/reset/:token',
      name: 'password-reset-form',
      component: () => import('@/views/PasswordResetForm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (Auth.check()) {
      next();

      return;
    } else {
      router.push('/login');
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
